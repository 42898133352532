<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Администрирование</template>
        <template #content>
          <TabView>
            <TabPanel header="Действия пользователей">
              <AbstractTable code="userActions" display-filter="menu">
                <template #user="row">
                  {{ row.data.surname }} {{ row.data.name }} {{ row.data.patronymic }}
                </template>
              </AbstractTable>
            </TabPanel>
          </TabView>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable.vue";

export default {
  name: "Admin",
  components: {AbstractTable},
  mounted() {
    if (this.$store.state.user.role !== 'admin') {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>